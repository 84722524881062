<template>
    <div class="my-coupons-box">
        <el-tabs v-model="tabId" @tab-click="tabChange" class="coupon-tabs">
            <el-tab-pane :label="item.name" :name="item.id" v-for="(item,index) in tabList" :key="index">
            </el-tab-pane>
        </el-tabs>
        <div >
            <su-coupon-item v-if="couponItemsList.length>0" :coupon="couponItemsList"></su-coupon-item>
            <su-empty v-else :txt="'暂无 '+tabList[tabCur]['name'] + ' 的创新券~~'"></su-empty>
        </div>
    </div>
</template>
<script>
    import suCouponItem from '@/components/su-coupon-item/su-coupon-item.vue' 
    import suEmpty from '@/components/others/su-empty.vue' 
    

    import ConfigUtil from '@/common/Config'
	import TimeUtil from '@/common/TimeUtil'
	import CouponService from '@/services/CouponService'
	import uni from '@/services/UNIService.js'

    export default{
        components:{
            suCouponItem,
            suEmpty
        },
        data() {
			return {
                tabId:'0',
				tabList: [{
					id: '0',
					name: '未使用'
				}, {
					id: '1',
					name: '已使用'
				}, {
					id: '3',
					name: '已过期'
				}],
				currentPage: 1,
				count: 15,
				couponItemsList: [],
				userCards: [],
				loadingStatus: "",
				tabCur:'0',
				currentUser: {
					id: uni.getStorageSync("userId")
				},
				couponItem: null,
				toAppId: 'wxa9e33a4f1da53239',
			}

		},
        created(){
            this.readyToLoad();
        },

        methods:{
            tabChange(tab,e) {
                // console.log(this.tabCur);
				let isChangeTabIndex = this.tabCur != tab.index
				if (isChangeTabIndex) {
                    console.log(tab.index);
                    // this.couponItemsList=[];
					this.tabCur = tab.index
					this.readyToLoad();
				}
			},
			async readyToLoad() {
				this.currentPage = 1;
				this.couponItemsList = []
				this.loadingStatus = "loading";
				// uni.pageScrollTo({
				// 	scrollTop: 0 //滚动到顶部位置
				// });
				this.doPageCouponItems();
			},
			async doPageCouponItems() {
				var couponItemInfo = await CouponService.doPageCouponItem(null, this.tabList[this.tabCur].id, null, this.currentPage, this.count);
				this.loadingStatus = "loading";
				if (couponItemInfo.status != 1 || !couponItemInfo.data || couponItemInfo.data.length <= 0) {
					this.loadingStatus = "noMore";
					return;
				}
				if (couponItemInfo.morePageCount <= 0) {
					this.loadingStatus = "noMore";
				} else {
					this.loadingStatus = "more";
				}
				var tempCouponList = [];
				for (var i = 0; i < couponItemInfo.data.length; i++) {
					var tempCoupon = CouponService.doGetCouponItemModel(couponItemInfo.data[i]);
					tempCoupon.coverSummary = "有效期：" + tempCoupon.startTime + "-" + tempCoupon.endTime;
					tempCoupon.isPlatform = (ConfigUtil.getBusinessTradingAreaId() == tempCoupon.tradingAreaId) ? true : false; //判断是否是该小程序平台对应的券
					tempCouponList.push(tempCoupon);
				}
				this.couponItemsList = this.couponItemsList.concat(tempCouponList);
				console.log(this.couponItemsList)
			},
        }
    }
</script>
<style lang="scss" scoped>
    .my-coupons-box{
        width:100%;
        min-height:600px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .coupon-tabs{
            /deep/.el-tabs__item{
                padding:0 20px;
            }
        }
    }
</style>